import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    styled,
    LinearProgress,
    Button,
    Collapse, Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RequireAdmin from "../auth/RequireAdmin";
import { Link } from "react-router-dom";
import ImageWithShadow from "./ImageWithShadow";
import {format, fromZonedTime, toDate} from 'date-fns-tz';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CelebrationIcon from '@mui/icons-material/Celebration';
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import SimpleBox from "./SimpleBox";
import BuildIcon from "@mui/icons-material/Build";
import ChatIcon from "@mui/icons-material/Chat";

const StripedLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 30,
    borderRadius: 5,
    backgroundColor: '#e0e0e0',
    boxShadow: `0px 3px 10px 0px #000`,
    '& .MuiLinearProgress-bar': {
        backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent)',
        backgroundSize: '4rem 4rem',
        animation: 'progress-bar-stripes 2s linear infinite',
        backgroundColor: value > 80 ? 'red' : value > 50 ? 'orange' : 'green',
    },
    '@keyframes progress-bar-stripes': {
        '0%': { backgroundPosition: '0 0' },
        '100%': { backgroundPosition: '4rem 0' },
    },
}));

const MyProgressBar = ({ value }) => (
    <Box sx={{ width: '100%' }}>
        <StripedLinearProgress variant="determinate" value={value} />
    </Box>
);

const categoryIcons = {
    0: { title: 'Battlepass', icon: <LocalActivityIcon /> },
    1: { title: 'Event', icon: <CelebrationIcon /> },
    2: { title: 'News', icon: <ArticleRoundedIcon /> },
    3: { title: 'DLC', icon: <ExtensionRoundedIcon /> },
    4: { title: 'Patch Notes', icon: <BuildIcon /> },
    5: { title: 'Dev-Talks', icon: <ChatIcon /> }
};

function renderCategoryIcon(event, setSelectedType) {
    const category = categoryIcons[event.category];
    return category ? (
        <Tooltip title={category.title}>
            <IconButton onClick={() => setSelectedType(event.category)}>
                {category.icon}
            </IconButton>
        </Tooltip>
    ) : null;
}

const EventCard = ({ event, defaultExpanded = false, isSmallScreen, setSelectedGame, setSelectedType}) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [shadowColor, setShadowColor] = useState('rgba(0, 0, 0, 0.3)');

    const extractPreviewContent = (description) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = description;
        const img = tempDiv.querySelector("img");
        const text = tempDiv.textContent || "";
        return {
            image: img ? img.src : null,
            text: text.slice(0, 300) + (text.length > 500 ? '...' : '') // Limiting to 500 chars
        };
    };
    const previewContent = extractPreviewContent(event.description);

    // Copy to clipboard function
    const handleCopyClick = () => {
        if (event.site_link) {
            navigator.clipboard.writeText(event.site_link).catch((error) => {
                console.error('Failed to copy: ', error);
            });
        }
    };

    // Set content loaded on expand
    useEffect(() => {
        if (expanded) {
            setContentLoaded(true);
        }
    }, [expanded]);

    // Convert UTC date to Zoned Date
    const convertToZonedDate = (dateStr) => {
        const utcDate = new Date(dateStr);
        const zonedDate = fromZonedTime(utcDate, 'UTC');
        return format(zonedDate, 'yyyy-MM-dd HH:mm');
    };

    // Calculate Time Left
    const calculateTimeLeft = (event) => {
        const startDate = toDate(fromZonedTime(event.event_start, 'UTC'));
        const endDate = toDate(fromZonedTime(event.event_end, 'UTC'));
        const currentDate = new Date();

        const totalDuration = endDate - startDate;
        const remainingTime = endDate - currentDate;

        if (remainingTime <= 0) {
            return {
                totalDays: 0,
                daysLeft: 0,
                hoursLeft: 0,
                minutesLeft: 0,
                progressPercentage: 0, // Event is over
            };
        }

        const daysLeft = remainingTime / (1000 * 60 * 60 * 24);
        const hoursLeft = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesLeft = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

        const elapsedTime = currentDate - startDate;
        const progressPercentage = Math.min((elapsedTime / totalDuration) * 100, 100); // Bound percentage between 0-100

        return {
            totalDays: totalDuration / (1000 * 60 * 60 * 24),
            daysLeft: parseFloat(daysLeft.toFixed(1)),
            hoursLeft,
            minutesLeft,
            progressPercentage, // This value will be passed to the progress bar
        };
    };


    // Extended Event Object
    const extendedEvent = {
        ...event,
        ...calculateTimeLeft(event),
    };

    // Time Remaining String
    const timeRemaining = (daysLeft, hoursLeft, minutesLeft, netStartDate) => {
        const currentTime = new Date();
        const eventStart =  toDate(fromZonedTime(netStartDate, 'UTC'));
        if (currentTime < eventStart) {
            const timeUntilStart = eventStart - currentTime;
            const hoursUntilStart = Math.floor(timeUntilStart / (1000 * 60 * 60));
            const minutesUntilStart = Math.floor((timeUntilStart % (1000 * 60 * 60)) / (1000 * 60));

            return `Starts in ${hoursUntilStart}h ${minutesUntilStart}m`;
        } else if (daysLeft === 0) {
            return 'Event is over.';
        } else if (daysLeft < 1) {
            return `${hoursLeft}h ${minutesLeft}m`;
        } else {
            return `${Math.floor(daysLeft)}d ${hoursLeft}h`;
        }
    };

    const localStartDate = convertToZonedDate(event.event_start);
    const localEndDate = convertToZonedDate(event.event_end);
    const timeRemainingText = timeRemaining(extendedEvent.daysLeft, extendedEvent.hoursLeft, extendedEvent.minutesLeft,  event.event_start);

    const logoUrl = `/images/games/${event.game_id}.webp`;

    return (
        <SimpleBox shadowColor={shadowColor}>
            <Grid container spacing={2} className="wrap">
                        {!isSmallScreen ? (
                            // Layout for large screens (md and up)
                            <>
                                <Grid item xs={2}>
                                    <ImageWithShadow src={logoUrl}
                                                     alt={event.title}
                                                     gameTitle={event.gameTitle}
                                                     shadowColor={shadowColor}
                                                     setShadowColor={setShadowColor}
                                                     setSelectedGame={setSelectedGame}
                                                     gameId={event.game_id}
                                    />
                                </Grid>
                                <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                    <Typography
                                        variant="h4"
                                        component="div"
                                        gutterBottom
                                        sx={{
                                            textShadow: '0px 2px 10px rgba(0, 0, 0, 1)' // Applying textShadow with RGBA color
                                        }}
                                    >
                                        {renderCategoryIcon(event, setSelectedType)}
                                        {event.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                    <RequireAdmin>
                                        <Button component={Link} to={`/editEvent/${event.id}`} variant="outlined" sx={{ ml: 2 }}>
                                            <EditIcon />
                                        </Button>
                                    </RequireAdmin>
                                    <Tooltip title={'Copy event address'}>
                                        <IconButton onClick={handleCopyClick}>
                                            <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
                                        </IconButton>
                                    </Tooltip>
                                    {(event.youtube_trailer || event.description || event.youtube_showcase) && (
                                        <Button onClick={() => setExpanded(!expanded)} variant={expanded ? 'contained' : 'outlined'} sx={{ mt: 2 }}>
                                            Show <RemoveRedEyeIcon sx={{ ml: 2 }}/>
                                        </Button>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            // Layout for small screens (below md)
                            <>
                                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }} >
                                    <ImageWithShadow src={logoUrl} alt={event.title} gameTitle={event.gameTitle} shadowColor={shadowColor} setShadowColor={setShadowColor} />
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                    <RequireAdmin>
                                        <Button component={Link} to={`/editEvent/${event.id}`} variant="outlined" sx={{ ml: 2 }}>
                                            <EditIcon />
                                        </Button>
                                    </RequireAdmin>
                                    <Tooltip title={'Copy event address'}>
                                        <IconButton onClick={handleCopyClick}>
                                            <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
                                        </IconButton>
                                    </Tooltip>
                                    {(event.youtube_trailer || event.description || event.youtube_showcase) && (
                                        <Button onClick={() => setExpanded(!expanded)} variant={expanded ? 'contained' : 'outlined'} sx={{ mt: 2 }}>
                                            <RemoveRedEyeIcon />
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                    <Typography
                                        variant="h4"
                                        component="div"
                                        gutterBottom
                                        sx={{
                                            textShadow: '0px 2px 10px rgba(0, 0, 0, 1)' // Applying textShadow with RGBA color
                                        }}
                                    >
                                        {renderCategoryIcon(event)}
                                        {event.title}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                <Grid item xs={12}>
                    {extendedEvent.progressPercentage > 0 && (
                        <MyProgressBar
                            value={extendedEvent.progressPercentage}
                            id={`event-progress-bar-${event.id}`}
                            aria-label={event.title}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!expanded && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                            {previewContent.image && (
                                <Box component="img" src={previewContent.image} alt="Preview" sx={{ width: previewContent.text ? '30%' : '100%', marginRight: previewContent.text ? 2 : 0 }} />
                            )}
                            {previewContent.text && (
                                <Typography variant="body2" sx={{ display: 'inline-block', flex: 1 }}>
                                    {previewContent.text}
                                    <Button onClick={() => setExpanded(true)} sx={{ textTransform: 'none' }}>Show more</Button>
                                </Typography>
                            )}
                        </Box>
                    )}
                    <Collapse in={expanded} >
                        {contentLoaded && (
                            <>
                                {event.youtube_trailer && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Trailer
                                        </Typography>
                                        <hr />
                                        <iframe
                                            width="100%"
                                            height="420px"
                                            src={`https://www.youtube.com/embed/${event.youtube_trailer}`}
                                            title="YouTube"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                )}
                                {event.description && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Description
                                        </Typography>
                                        <hr />
                                        <Typography variant="body2" color="text.secondary">
                                            <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                                        </Typography>
                                    </Box>
                                )}
                                {event.youtube_showcase && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Showcase
                                        </Typography>
                                        <hr />
                                        <iframe
                                            width="100%"
                                            height="420px"
                                            src={`https://www.youtube.com/embed/${event.youtube_showcase}`}
                                            title="YouTube"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                )}
                            </>
                        )}
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid>
                            <Typography variant="body" color="text.secondary">
                                {event.event_start_est === 1 && (
                                    <Tooltip title={`Not officially confirmed yet!`}>
                                        <IconButton>
                                            <InfoOutlinedIcon color="info" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {localStartDate}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="h5">
                                {timeRemainingText}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="body" color="text.secondary">
                                {localEndDate}
                                {event.event_end_est === 1 && (
                                    <Tooltip title={`Not officially confirmed yet!`}>
                                        <IconButton>
                                            <InfoOutlinedIcon color="info" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default EventCard;
