import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SessionDataProvider} from "./components/auth/SessionDataProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter} from "react-router-dom";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9', // Customize primary color
        },
        secondary: {
            main: '#f48fb1', // Customize secondary color
        },
        background: {
            default: '#121212', // Customize background color
            paper: '#1d1d1d',
        },
    },
});

const randomImageNumber = Math.floor(Math.random() * 27) + 1;
const randomBackgroundImage = `/images/wallpapers/${randomImageNumber}.webp`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <SessionDataProvider>
          <ThemeProvider theme={darkTheme}>
              <BrowserRouter>
                  <CssBaseline />
                  <div style={{
                      width: '100%',
                      backgroundImage: `url(${randomBackgroundImage})`, // Use random image
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundAttachment: 'fixed',
                      backgroundColor: 'rgba(33, 33, 33, 0.7)',
                  }}>
                      <App />
                  </div>
              </BrowserRouter>
          </ThemeProvider>
        </SessionDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
