import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';

function ShowTitle({ title, item = null, sx = null, description = null, keywords = null, noShow = false, canonical = null }) {
    const [baseDescription, setBaseDescription] = useState(description);
    const baseTitle = "Season-Tracker - ";
    const canonicalUrl = canonical ?? 'https://season-tracker.com' + window.location.pathname;

    useEffect(() => {
        if (item && item.description) {
            setBaseDescription(item.description);
        }
    }, [item]);

    return (
        <>
            <Helmet>
                <title>{baseTitle} {title}</title>
                {baseDescription && <meta name="description" content={baseDescription} />}
                {keywords && <meta name="keywords" content={keywords} />}
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            {!noShow && (
                <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
                    <Typography component="h1" sx={{ fontSize: '1.5rem', fontWeight: 'bold', fontVariant: 'small-caps' }}>
                        {title}
                    </Typography>
                </Box>
            )}
        </>
    );
}

export default ShowTitle;
