import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";
import SimpleBox from "../util/SimpleBox";
import { useParams } from 'react-router-dom';

export default function CreateGame({ games }) {
    const { gameId } = useParams();
    const [inputs, setInputs] = useState({});
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const getGameById = (id) => {
        return games.find(game => String(game.id) === String(id)) || "Unknown Game";
    };

    useEffect(() => {
        if (gameId) {
            const gameData = getGameById(gameId);
            console.log(gameData);
                setInputs({
                    title: gameData.title,
                    meta_description: gameData.meta_description,
                    meta_keywords: gameData.meta_keywords,
                });
        }
    }, [gameId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('image', imageFile);

        const apiCall = gameId
            ? axios.post(`/api/edit/game/${gameId}`, formData)
            : axios.post('/api/create/game', formData);

        apiCall
            .then(response => {
                console.log(response);
                setInputs((prevInputs) => ({
                    ...prevInputs
                }));
                setImageSrc(null);
                setImageFile(null);
                setSnackOpen(true);
                setMessage(gameId ? 'Sikeres játék frissítés' : 'Sikeres játék hozzáadás');
                setSeverity('success');
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                setSnackOpen(true);
                setMessage('Hiba történt');
                setSeverity('error');
            });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
            setImageFile(file);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <SimpleBox sx={{ p: 3, width: '75%', alignItems: 'center', justifyContent: 'center', m: '0 auto', mt: 5 }}>
                <Box component="form" onSubmit={handleSubmit}>
                    <InputLabel id="main-cat-label">
                        <h3>{gameId ? 'Játék Szerkesztése' : 'Játék Hozzáadás'}</h3>
                    </InputLabel>
                    <CustomFileInput handleImageChange={handleImageChange}
                                     customText={"Tölts fel vagy húzz ide képet"}/>
                    {imageSrc && <img src={imageSrc} alt="preview" height="100"/>}
                    <Grid>
                        <TextField
                            fullWidth
                            id="title"
                            label="Játék neve"
                            name="title"
                            value={inputs.title || ''}
                            onChange={handleChange}
                            sx={{mt: 2}}
                        />
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 5, mb: 2, p: 2 }}
                    >
                        {gameId ? 'Frissítés' : 'Hozzáadás'}
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity}/>
            </SimpleBox>
        </>
    );
}
