import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import EventCard from "./util/eventCard";
import NewsCard from "./util/NewsCard";

export default function EventList({ events = [], games = [], selectedGame, setSelectedGame, sortType, drawerOpen, isSmallScreen, selectedType, setSelectedType }) {
    const [sortedEvents, setSortedEvents] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [newsList, setNewsList] = useState([]);

    const getGameTitleById = (id) => {
        return games.find(game => String(game.id) === String(id))?.title || "Unknown Game";
    };

    useEffect(() => {
        // Ensure events is an array
        const filteredEvents = Array.isArray(events) ? events.filter(event => {
            // Check if event matches the selectedGame if it's defined
            const matchesGame = selectedGame ? event.game_id === Number(selectedGame) : true;

            // Check if event matches selectedType if it's not -1
            const matchesCategory = selectedType !== -1 ? Number(event.category) === Number(selectedType) : true;

            // Return true if both conditions match
            return matchesGame && matchesCategory;
        }) : [];

        const eventsWithAdditionalData = filteredEvents.map(event => {

            return {
                ...event,
                gameTitle: getGameTitleById(event.game_id),
            };
        });

        // Separate events into categories
        const categories = {
            battlepass: [],
            event: [],
            news: [],
            dlc: [],
            patchNote: [],
            devTalk: []
        };

        // Check if filteredEvents is an array before using forEach
        if (Array.isArray(eventsWithAdditionalData)) {
            eventsWithAdditionalData.forEach(event => {
                switch (event.category) {
                    case 0:
                        categories.battlepass.push(event);
                        break;
                    case 1:
                        categories.event.push(event);
                        break;
                    case 2:
                        categories.news.push(event);
                        break;
                    case 3:
                        categories.dlc.push(event);
                        break;
                    case 4:
                        categories.patchNote.push(event);
                        break;
                    case 5:
                        categories.devTalk.push(event);
                        break;
                    default:
                        break;
                }
            });
        }

        // Combine lists based on category order
        const eventListArray = [
            ...categories.battlepass,
            ...categories.event,
        ];
        setEventList(eventListArray);

        const newsListArray = [
            ...categories.news,
            ...categories.dlc,
            ...categories.patchNote,
            ...categories.devTalk
        ];
        setNewsList(newsListArray);

        // Sort the combined list
        const sorted = eventListArray.sort((a, b) => {
            // First, sort by type
            const typeOrder = ['battlepass', 'event', 'news', 'dlc', 'patchNote', 'devTalk'];
            const typeComparison = typeOrder.indexOf(a.category) - typeOrder.indexOf(b.category);

            if (typeComparison !== 0) return typeComparison;

            // Apply further sorting based on sortType
            if (sortType === 'event_start') {
                return new Date(a.event_start) - new Date(b.event_start);
            } else if (sortType === 'event_end') {
                return new Date(a.event_end) - new Date(b.event_end);
            } else if (sortType === 'daysLeft') {
                const daysLeftA = Math.max(Math.ceil((new Date(a.event_end) - new Date()) / (1000 * 60 * 60 * 24)), 0);
                const daysLeftB = Math.max(Math.ceil((new Date(b.event_end) - new Date()) / (1000 * 60 * 60 * 24)), 0);
                return daysLeftA - daysLeftB;
            } else if (sortType === 'daysLeftReverse') {
                const daysLeftA = Math.max(Math.ceil((new Date(a.event_end) - new Date()) / (1000 * 60 * 60 * 24)), 0);
                const daysLeftB = Math.max(Math.ceil((new Date(b.event_end) - new Date()) / (1000 * 60 * 60 * 24)), 0);
                return daysLeftB - daysLeftA;
            } else if (sortType === 'alphabetical') {
                const titleA = getGameTitleById(a.game_id);
                const titleB = getGameTitleById(b.game_id);
                return titleA.localeCompare(titleB);
            }

            return 0;
        });

        // Only update state if there is a change
        setSortedEvents(prevEvents => {
            if (JSON.stringify(prevEvents) !== JSON.stringify(sorted)) {
                return sorted;
            }
            return prevEvents;
        });

        console.log("eventlist: ", eventList);
    }, [events, selectedGame, sortType, selectedType, games]);

    if (selectedType !== -1) {
        return (
            <Grid container spacing={3} sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
                <Grid item xs={12} >
                    <Grid container spacing={2} >
                        {sortedEvents.length > 0 && (
                            sortedEvents.map(event => (
                                <Grid item xs={12} lg={6} key={event.id}>
                                    <EventCard event={event} isSmallScreen={isSmallScreen} setSelectedGame={setSelectedGame} setSelectedType={setSelectedType} />
                                </Grid>
                            ))
                        )}
                        {newsList.length > 0 && (
                            newsList.map(event => (
                                <Grid item xs={12} lg={6} key={event.id}>
                                    <NewsCard event={event} games={games} setSelectedGame={setSelectedGame} setSelectedType={setSelectedType}/>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={3} sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignSelf: 'center'}}>
            <Grid item xs={12} xl={6} >
                <Grid container spacing={2} >
                    {sortedEvents.length > 0 && (
                        sortedEvents.map(event => (
                            <Grid item xs={12} key={event.id}>
                                <EventCard event={event} isSmallScreen={isSmallScreen} setSelectedGame={setSelectedGame} setSelectedType={setSelectedType} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
                <Grid container spacing={2}>
                    {newsList.length > 0 && (
                        newsList.map(event => (
                            <Grid item xs={12} key={event.id}>
                                <NewsCard event={event} games={games} setSelectedGame={setSelectedGame} setSelectedType={setSelectedType}/>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
