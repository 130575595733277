import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    styled,
    LinearProgress,
    Button,
    Collapse, Tooltip, Dialog,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RequireAdmin from "../auth/RequireAdmin";
import { Link } from "react-router-dom";
import ImageWithShadow from "./ImageWithShadow";
import {format, fromZonedTime} from 'date-fns-tz';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CelebrationIcon from '@mui/icons-material/Celebration';
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import SimpleBox from "./SimpleBox";
import Divider from "@mui/material/Divider";
import BuildIcon from "@mui/icons-material/Build";
import ChatIcon from "@mui/icons-material/Chat";

const StripedLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 30,
    borderRadius: 5,
    backgroundColor: '#e0e0e0',
    boxShadow: `0px 3px 10px 0px #000`,
    '& .MuiLinearProgress-bar': {
        backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent)',
        backgroundSize: '4rem 4rem',
        animation: 'progress-bar-stripes 2s linear infinite',
        backgroundColor: value > 80 ? 'red' : value > 50 ? 'orange' : 'green',
    },
    '@keyframes progress-bar-stripes': {
        '0%': { backgroundPosition: '0 0' },
        '100%': { backgroundPosition: '4rem 0' },
    },
}));

const categoryIcons = {
    0: { title: 'Battlepass', icon: <LocalActivityIcon /> },
    1: { title: 'Event', icon: <CelebrationIcon /> },
    2: { title: 'News', icon: <ArticleRoundedIcon /> },
    3: { title: 'DLC', icon: <ExtensionRoundedIcon /> },
    4: { title: 'Patch Notes', icon: <BuildIcon /> },
    5: { title: 'Dev-Talks', icon: <ChatIcon /> }
};

function renderCategoryIcon(event, setSelectedType) {
    const category = categoryIcons[event.category];
    return category ? (
        <Tooltip title={category.title}>
            <IconButton onClick={() => setSelectedType(event.category)}>
                {category.icon}
            </IconButton>
        </Tooltip>
    ) : null;
}

const NewsCard = ({ event, games, defaultExpanded = false, presetGameTitle = null, setSelectedGame, setSelectedType }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [shadowColor, setShadowColor] = useState('rgba(0, 0, 0, 0.3)');
    const gameTitle = presetGameTitle || (games.find(game => game.id === String(event.game_id))?.title || "Unknown Game");
    const category = categoryIcons[event.category];

    const extractPreviewContent = (description) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = description;
        const img = tempDiv.querySelector("img");
        const text = tempDiv.textContent || "";
        return {
            image: img ? img.src : null,
            text: text.slice(0, 300) + (text.length > 500 ? '...' : '') // Limiting to 500 chars
        };
    };
    const previewContent = extractPreviewContent(event.description);

    const handleCopyClick = () => {
        if (event.site_link) {
            navigator.clipboard.writeText(event.site_link).then(() => {
            }).catch((error) => {
                console.error('Failed to copy: ', error);
            });
        }
    };

    useEffect(() => {
        if (expanded) {
            setContentLoaded(true);
        }
    }, [expanded]);

    const convertToZonedDate = (dateStr) => {
        const utcDate = new Date(dateStr);
        const zonedDate = fromZonedTime(utcDate, 'UTC');
        return format(zonedDate, 'yyyy-MM-dd HH:mm');
    };

    const localStartDate = convertToZonedDate(event.event_start);
    const localEndDate = convertToZonedDate(event.event_end);

    // Calculate progress percentage
    const totalDays = Math.ceil((new Date(event.event_end) - new Date(event.event_start)) / (1000 * 60 * 60 * 24)); // Total duration in days
    const progressPercentage = Math.min(((totalDays - event.daysLeft) / totalDays) * 100, 100); // Calculate progress

    const logoUrl = "/images/games/" + event.game_id + ".webp";

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClickImage = (e) => {
        if (e.target.tagName === 'IMG') {
            setSelectedImage(e.target.src); // Get the clicked image's src
            setOpen(true); // Open the dialog
        }
    };

    const handleClose = () => {
        setOpen(false); // Close the dialog
        setSelectedImage(null); // Reset the selected image
    };

    return (
            <SimpleBox>
                <Grid container spacing={2} className="wrap">
                    <Grid item xs={12} md={2}>
                        <ImageWithShadow src={logoUrl}
                                         alt={event.title}
                                         gameTitle={gameTitle}
                                         shadowColor={shadowColor}
                                         setShadowColor={setShadowColor}
                                         setSelectedGame={setSelectedGame}
                                         gameId={event.game_id}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} >
                        <Typography
                            variant="h4"
                            component="div"
                            gutterBottom
                            sx={{
                                textShadow: '0px 2px 10px rgba(0, 0, 0, 1)' // Applying textShadow with RGBA color
                            }}
                        >
                            {event.title}
                        </Typography>
                        <Divider sx={{ border: '1px solid #fff', width: '90%', mt: 1, boxShadow: '0px 2px 10px rgba(0, 0, 0, 1)' }}  />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <RequireAdmin>
                            <Button component={Link} to={`https://season-tracker.com/editEvent/${event.id}`} variant="outlined" sx={{ ml: 2 }}>
                                <EditIcon />
                            </Button>
                        </RequireAdmin>
                        <Tooltip title={`Copy event address`}>
                            <IconButton onClick={handleCopyClick}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {(event.youtube_trailer || event.description || event.youtube_showcase) && (
                            <Tooltip title={`View Details`}>
                                <Button onClick={() => setExpanded(!expanded)} variant={expanded ? 'contained' : 'outlined'} sx={{ mt: 2 }}>
                                    Show <RemoveRedEyeIcon sx={{ ml: 2 }}/>
                                </Button>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {!expanded && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                {previewContent.image && (
                                    <Box component="img" src={previewContent.image} alt="Preview" sx={{ width: previewContent.text ? '30%' : '100%', marginRight: previewContent.text ? 2 : 0 }} />
                                )}
                                {previewContent.text && (
                                    <Typography variant="body2" sx={{ display: 'inline-block', flex: 1 }}>
                                        {previewContent.text}
                                        <Button onClick={() => setExpanded(true)} sx={{ textTransform: 'none' }}>Show more</Button>
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <Collapse in={expanded} >
                            {contentLoaded && (
                                <>
                                    {event.youtube_trailer && (
                                        <Box sx={{ ml: 1, p: 2 }}>
                                            <Typography variant="h5" color="text.secondary">
                                                Trailer
                                            </Typography>
                                            <hr />
                                            <iframe
                                                width="100%"
                                                height="420px"
                                                src={`https://www.youtube.com/embed/${event.youtube_trailer}`}
                                                title="YouTube"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </Box>
                                    )}
                                    {event.description && (
                                        <>
                                            <Box sx={{ ml: 1, p: 2 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: event.description }}
                                                        onClick={handleClickImage}
                                                    ></div>
                                                </Typography>
                                            </Box>

                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                maxWidth="xl"
                                                fullWidth
                                                PaperProps={{
                                                    sx: {
                                                        backgroundColor: 'rgba(29, 29, 29, 0.5)', // Apply to the dialog content
                                                    },
                                                }}
                                            >
                                                <Box sx={{ p: 2, textAlign: 'center' }}>
                                                    <img
                                                        src={selectedImage}
                                                        alt="enlarged"
                                                        style={{
                                                            maxWidth: '100%',  // Restrict to the dialog width
                                                            width: 'auto',      // Use natural width up to max-width
                                                            height: 'auto'      // Keep aspect ratio
                                                        }}
                                                    />
                                                </Box>
                                            </Dialog>
                                        </>
                                    )}
                                    {event.youtube_showcase && (
                                        <Box sx={{ ml: 1, p: 2 }}>
                                            <Typography variant="h5" color="text.secondary">
                                                Showcase
                                            </Typography>
                                            <hr />
                                            <iframe
                                                width="100%"
                                                height="420px"
                                                src={`https://www.youtube.com/embed/${event.youtube_showcase}`}
                                                title="YouTube"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Collapse>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Typography
                                variant="body"
                                component="div"
                                gutterBottom
                                sx={{
                                    shadow: '0px 2px 10px rgba(0, 0, 0, 1)' // Applying textShadow with RGBA color
                                }}
                            >
                                {renderCategoryIcon(event, setSelectedType)} {category.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography variant="body" color="text.secondary">
                                {localEndDate}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleBox>
    );
};

export default NewsCard;
