import './App.scss';
import Header from "./components/header";
import {BrowserRouter, Routes, Route, useParams, useNavigate} from 'react-router-dom';
import Grid from "@mui/material/Grid";
import axios from "axios";
import EventList from "./components/eventList";
import { useSessionData } from "./components/auth/SessionDataProvider";
import React, { useEffect, useState } from "react";
import RequireAdmin from "./components/auth/RequireAdmin";
import CreateGame from "./components/create/CreateGame";
import EditEvent from "./components/create/EditEvent";
import EventForm from "./components/create/EventForm";
import ShowSnack from "./components/util/ShowSnack";
import EventPage from "./components/util/EventPage";
import ListEvents from "./components/auth/ListEvents";
import {useMediaQuery} from "@mui/system";
import SidebarDrawer from "./components/util/SidebarDrawer";
import MainPage from "./components/pages/MainPage";
import {Typography} from "@mui/material";
import ListGames from "./components/auth/ListGames";

function App() {
    const { sessionData, setSessionData, isOnPath } = useSessionData();
    const navigate = useNavigate();
    const [ games, setGames ] = useState([]);
    const [ events, setEvents ] = useState([]);
    const [ selectedGame, setSelectedGame ] = useState(null);
    const [ selectedType, setSelectedType ] = useState(-1);
    const [sortType, setSortType] = useState('daysLeft');
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    const [loading, setLoading] = React.useState(true);
    const [data, setData ] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(!isSmallScreen);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    useEffect(() => {
        getSessionData();
        getAllData();

        setSnackOpen(true);
        setMessage('Event times are converted to your local time!');
        setSeverity('info');
    }, []);

    useEffect(() => {
        if (data && data.games) {
            // Convert games object to an array of objects with id and name properties
            const gamesArray = Object.entries(data.games).map(([id, title]) => ({
                id,
                title
            }));
            setGames(gamesArray);
        }
        console.log("sessionData: ",sessionData);
    }, [data]);

    const handleUrl = () => {
        const { hostname, pathname } = window.location;
        // const subdomain = hostname.split('.')[0];  // Subdomain case
        const path = pathname.split('/')[1];  // Path case, e.g., 'once_human' or 'games'

        // Check if the path matches a direct game name or under '/games'
        let gameUrlSegment = path;

        if (path === 'game') {
            // If we are in `/games/once_human`, check the second part for the game
            gameUrlSegment = pathname.split('/')[2];
        }

        // Normalize the gameUrlSegment to match titles (lowercase and replace underscores)
        // const normalizedUrl = (subdomain || gameUrlSegment).toLowerCase();
        const normalizedUrl = gameUrlSegment.toLowerCase();

        // Find the target game based on the normalized title
        const targetGame = games.find(game => {
            const normalizedTitle = game.title
                ? game.title.replace(/ /g, '_').toLowerCase()
                : '';
            return normalizedTitle === normalizedUrl;
        });

        if (targetGame) {
            setSelectedGame(targetGame.id);
            const gameTitleForUrl = targetGame.title.replace(/\s+/g, '_').toLowerCase();

            if (path !== 'game') {
                navigate(`/game/${gameTitleForUrl}`);
            }
        }
    };

    useEffect(() => {
        handleUrl();
    }, [games]);

    function getSessionData() {
        axios.get('/api/user/session')
            .then((result) => {
                if (result.data !== false) {
                    setSessionData({
                        ...sessionData,
                        userId: result.data.user_id,
                        rights: result.data.rights,
                        logged: result.data.logged,
                        username: result.data.username,
                        email: result.data.email,
                        isUserAdmin: result.data.rights === 5,
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getAllData() {
        axios.get('/api/get/allData')
            .then((result) => {
                console.log(result);
                if (result.data !== false) {
                    setData(result.data);
                    setEvents(result.data.activeEvents);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
            <div className="App">
                    <Header games={games}
                            selectedGame={selectedGame}
                            setSelectedGame={setSelectedGame}
                            sortType={sortType}
                            setSortType={setSortType}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                    />
                    <SidebarDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} loading={loading} setSelectedGame={setSelectedGame} setSelectedType={setSelectedType} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Routes>
                                <Route index path="/" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/game/:game" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/news" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/events" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/battlepass" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/dlcs" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/patchnotes" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/devtalks" element={
                                    <MainPage events={events}
                                              games={games}
                                              selectedGame={selectedGame}
                                              setSelectedGame={setSelectedGame}
                                              sortType={sortType}
                                              drawerOpen={drawerOpen}
                                              isSmallScreen={isSmallScreen}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                                    />}
                                />
                                <Route path="/games/:game/:event/:title" element={<EventPage games={games} />} />
                            </Routes>
                            <RequireAdmin>
                                <Routes>
                                    <Route path="/addGame" element={<CreateGame games={games} />} />
                                    <Route path="/editGame/:gameId" element={<CreateGame games={games} />} />
                                    <Route path="/listEvents" element={<ListEvents games={games} />} />
                                    <Route path="/listGames" element={<ListGames games={games} />} />
                                    <Route path="/addEvent" element={<EventForm games={games}/>} />
                                    <Route path="/editEvent/:id" element={<EditEvent games={games} />} />
                                </Routes>
                            </RequireAdmin>
                        </Grid>
                    </Grid>

                {/* FOOTER */}
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        display: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 5
                    }}>
                        Season-Tracker is an independent fan site created to help users track progress and updates for various games. We are not affiliated with any of the game companies, and they are not responsible for the content or privacy policy of this site.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        m: 2
                    }}>
                        <a href="/privacy_policy.html" className="plain-link">Privacy Policy</a> | <a href="/terms_of_use.html" className="plain-link">Terms of Use</a> | © 2024 Season-Tracker.com
                    </Typography>
                </Grid>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
            </div>
    );
}

export default App;
