import {useSessionData} from "./auth/SessionDataProvider";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {
    Autocomplete,
    TextField,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    styled,
    Tooltip, FormControl, Select, MenuItem,
} from '@mui/material';
import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import ContentBox from "./util/ContentBox";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";

const LogoAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius * 2, // Adjust this for the desired curve
    boxShadow: theme.shadows[3], // Adds a small shadow behind the avatar
}));

export default function Header({ games, selectedGame, setSelectedGame, sortType, setSortType, selectedType, setSelectedType }) {
    const { sessionData } = useSessionData();
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        const gameId = newValue?.id || null;
        setSelectedGame(gameId);
        navigate("/");
    };

    const handleSortToggle = () => {
        setSortType(prevSortType => prevSortType === 'daysLeft' ? 'daysLeftReverse' : 'daysLeft');
    };

    const LogOut = ()=>{
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('rights');
        localStorage.clear();
        axios.post('/api/user/close_session')
            .then(()=>{
                window.location.href = '/';
            })
    };

    useEffect(() => {
        if (selectedGame) {
            const game = games.find(game => String(game.id) === String(selectedGame)) || "Unknown Game";
            if (game) {
                setSelectedGame(game.id);
            }
        }
    }, [selectedGame, games, setSelectedGame]);

    return (
        <ContentBox sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            display: 'flex',
            flexDirection: 'row',
        }}>
            <Grid container>
                <Grid item xs={12} md={9} sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Tooltip title="Sort by alphabetically">
                        <IconButton onClick={() => setSortType('alphabetical')} >
                            <SortByAlphaIcon sx={{ color: '#000' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Sort by days left">
                        <IconButton onClick={handleSortToggle}>
                            <AvTimerIcon sx={{ color: '#000' }} />
                        </IconButton>
                    </Tooltip>
                    <Autocomplete
                        id="game_id"
                        name="game_id"
                        value={games.find(option => option.id === selectedGame) || null}
                        onChange={handleChange}
                        options={games}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                            <ListItem
                                {...props}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Hover background color
                                        cursor: 'pointer', // Ensure cursor is a pointer
                                    },
                                    backgroundColor: (selectedGame === option.id) ? 'rgba(0, 0, 0, 0.14)' : 'transparent', // Background color for selected item
                                    border: (selectedGame === option.id) ? '2px solid #1976d2' : 'none', // Border for selected item
                                    borderRadius: '4px', // Border radius for rounded corners
                                }}
                            >
                                <ListItemAvatar>
                                    <LogoAvatar
                                        src={`/images/games/${option.id}.webp`}
                                        alt={option.title}
                                        sx={{ mr: 1 }}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={option.title} />
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Games'}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: selectedGame && (
                                        <img
                                            src={`/images/games/${selectedGame}.webp`}
                                            alt={games.find(option => option.id === selectedGame)?.title || ''}
                                            style={{ marginRight: '8px', width: '37px', height: '37px', border: '2px solid #000', borderRadius: '4px' }}
                                        />
                                    ),
                                }}
                            />
                        )}
                        sx={{
                            flexGrow: 1,
                            ml: 2,
                            '&.Mui-focused-label': {
                                color: '#000', // Label color when focused
                            },
                            color: '#000', // Text color of the label
                            '& .MuiInputBase-input': {
                                color: '#000', // Text color within the input field
                            },
                            '& .MuiFormLabel-root': {
                                color: '#000', // Color of the label text
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#000', // Border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#000', // Border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#000', // Border color when focused
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel
                            id="selectedType-label"
                            sx={{
                                ml: 2,
                                mr: 1,
                                color: '#000',
                                '&.Mui-focused': {
                                    color: '#000',
                                },
                            }}
                        >
                            Type
                        </InputLabel>
                        <Select
                            id="selectedType"
                            name="selectedType"
                            labelId="selectedType-label"
                            value={selectedType}
                            onChange={(event) => setSelectedType(event.target.value)}
                            label="Type"
                            defaultValue={selectedType}
                            sx={{
                                ml: 2,
                                mr: 1,
                                width: '100%',
                                color: '#000',
                                '& .MuiInputBase-input': {
                                    color: '#000',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#000',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#000',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#000',
                                },
                            }}
                        >
                            <MenuItem key={-1} value={-1}> All </MenuItem>
                            <MenuItem key={0} value={0}> Battlepass </MenuItem>
                            <MenuItem key={1} value={1}> Event </MenuItem>
                            <MenuItem key={2} value={2}> News </MenuItem>
                            <MenuItem key={3} value={3}> DLC </MenuItem>
                            <MenuItem key={4} value={4}> Patch Note </MenuItem>
                            <MenuItem key={5} value={5}> Dev-Talk </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </ContentBox>
    );
}
