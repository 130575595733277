import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';
import {Tooltip, tooltipClasses} from "@mui/material";

const LogoAvatar = styled(Avatar)(({ theme, shadowColor }) => ({
    width: 100,
    height: 100,
    borderRadius: theme.shape.borderRadius * 2,
    border: '2px solid black',
    marginRight: theme.spacing(2),
    boxShadow: `0px 5px 10px 0px ${shadowColor}`,
}));

const TitleTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1.2rem', // Adjust the font size here
        padding: theme.spacing(1),
    },
}));

function ImageWithShadow({ src, alt, gameTitle = 'Unkown', shadowColor, setShadowColor, setSelectedGame, gameId }) {

    useEffect(() => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = src;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;

            let r = 0, g = 0, b = 0;

            for (let i = 0; i < data.length; i += 4) {
                r += data[i];
                g += data[i + 1];
                b += data[i + 2];
            }

            r = Math.floor(r / (data.length / 4));
            g = Math.floor(g / (data.length / 4));
            b = Math.floor(b / (data.length / 4));

            const avgColor = `rgba(${r}, ${g}, ${b}, 0.7)`;
            setShadowColor(avgColor);
        };
    }, [src]);

    return (
        <TitleTooltip title={gameTitle}>
            <LogoAvatar src={src} alt={alt} shadowColor={shadowColor} onClick={() => setSelectedGame(gameId)} sx={{ cursor: 'pointer'}} />
        </TitleTooltip>
    );
}

export default ImageWithShadow;
