import React, { useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { Button, CircularProgress } from '@mui/material';
import Grid from "@mui/material/Grid";
import SimpleBox from "../util/SimpleBox";

const columns = () => [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'title', headerName: 'Original Name', width: 420 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params) => (
            <>
                <Button variant="contained" color="info" component={Link} to={`/editGame/${params.row.id}`} sx={{ mr: "10px" }}>Edit</Button>
            </>
        ),
    },
];

export default function ListGames({ games }) {

    return (
        <SimpleBox sx={{ p: 3, width: '75%', alignItems: 'center', justifyContent: 'center', m: '0 auto', mt: 5 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <div style={{ height: 1000, width: '100%' }}>
                        <DataGrid
                            rows={games}
                            columns={columns()}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10]}
                            disableSelectionOnClick
                        />
                    </div>
                </Grid>
            </Grid>
        </SimpleBox>
    );
}
