import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EventCard from "./eventCard";
import {CircularProgress, circularProgressClasses} from "@mui/material";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ShowTitle from "./ShowTitle";

const EventPage = ({ games }) => {
    const { game, event, title } = useParams();
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const extractId = (filename) => {
        const match = filename.match(/^(\d+)_/); // Match the number at the beginning of the filename
        return match ? match[1] : null;
    };

    const id = extractId(title);

    // Calculate days left for events
    const calculateDaysLeft = (event) => {
        const endDate = new Date(event.event_end);
        const currentDate = new Date();
        const daysLeft = Math.max(Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24)), 0);
        return daysLeft;
    };

    const getGameTitleById = (id) => {
        return games.find(game => String(game.id) === String(id))?.title || "Unknown Game";
    };

    const fetchContent = async () => {
        try {
            axios.get(`/api/get/event/${id}`)
                .then(function (response) {
                    const newEvent = response.data;
                    console.log(response.data);
                    const daysLeft = calculateDaysLeft(newEvent);
                    const gameTitle = getGameTitleById(newEvent.game_id);
                    setSelectedEvent({ ...newEvent, daysLeft, gameTitle });
                });
        } catch (error) {
            console.error('Error fetching content:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(games) {
            fetchContent();
        }
    }, [games, title]);

    if (loading) {
        return <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={(theme) => ({
                color: '#1a90ff',
                animationDuration: '550ms',
                position: 'middle',
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                ...theme.applyStyles('dark', {
                    color: '#308fe8',
                }),
            })}
            size={40}
            thickness={4}
        />;
    }

    if (selectedEvent) {
        return (
                <Grid container spacing={2} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', mt: 2}}>
                    <ShowTitle
                        noShow={true}
                        title={`${selectedEvent.gameTitle} | ${selectedEvent.title}`}
                        description={selectedEvent.meta_description}
                        keywords={selectedEvent.meta_keywords}
                        canonical={selectedEvent.site_link}
                    />
                    <Grid item xs={9} key={selectedEvent.id} sx={{ mb: 3 }}>
                        <EventCard games={games}
                                   event={selectedEvent}
                                   defaultExpanded={true}
                        />
                    </Grid>
                </Grid>
        );
    }

};

export default EventPage;
