import EventForm from "./EventForm";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {CircularProgress, circularProgressClasses} from "@mui/material";

function EditEvent({ games }) {
    const { id } = useParams();
    const [event, setEvent] = useState(null);

    useEffect(() => {
        if (id) {
            axios.get(`/api/get/event/${id}`).then((response) => {
                setEvent(response.data);
            });
        }
    }, [id]);

    if (!event) {
        return <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={(theme) => ({
                color: '#1a90ff',
                animationDuration: '550ms',
                position: 'middle',
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                ...theme.applyStyles('dark', {
                    color: '#308fe8',
                }),
            })}
            size={40}
            thickness={4}
        />;
    }

    return <EventForm event={event} games={games} />;
}

export default EditEvent;
